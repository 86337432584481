// src/pages/ShareShowScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Copy } from 'lucide-react';

function ShareShowScreen() {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { showId, visibility, showName } = location.state || {};

  const [storyVisibility, setStoryVisibility] = useState(visibility);
  const [dropDownVisibility, setDropDownVisibility] = useState(visibility);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const publicLink = `https://www.kempo.com/user/${user.user_id}/show/${showId}`;

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate('/login');
    } else if (!showId) {
      navigate('/shows-list');
    }
  }, [user.isAuthenticated, showId, navigate]);

  useEffect(() => {
    setStoryVisibility(visibility);
    setDropDownVisibility(visibility);
  }, [visibility]);

  if (!showId || !user.user_id) {
    return <div style={styles.errorMessage}>Error: Missing show or user information</div>;
  }

  const handleDropdownChange = (e) => {
    setDropDownVisibility(e.target.value);
  };

  const isChangeDisabled = storyVisibility === dropDownVisibility || isLoading;

  const handleChangeVisibility = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/set-show-visibility`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: user.user_id,
          showId: showId,
          visibility: dropDownVisibility,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setStoryVisibility(dropDownVisibility);
        setSuccessMessage('Show visibility updated successfully');
      } else {
        throw new Error(data.error || 'Failed to update show visibility');
      }
    } catch (error) {
      console.error('Error updating show visibility:', error);
      setError(`Error updating show visibility: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(publicLink).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.linkContainer, textAlign: 'left', marginBottom: '20px' }}>
        <Link to="/shows-list" style={styles.link}>&lt;&lt; Back to Shows List</Link>
      </div>
      <h1 style={styles.sectionHeader}>Share Show</h1>
      <h2 style={styles.shareShowName}>{showName}</h2>
      <div style={styles.formContainer}>
        <div style={styles.formGroup}>
          <label htmlFor="visibility-dropdown" style={styles.label}>Visibility:</label>
          <select 
            id="visibility-dropdown" 
            value={dropDownVisibility} 
            onChange={handleDropdownChange}
            style={styles.select}
          >
            <option value="Public">Public (recommended)</option>
            <option value="Private">Private</option>
          </select>
          <p style={styles.visibilityDescription}>
            {dropDownVisibility === 'Public' 
              ? "Visible on the web and shareable" 
              : "Visible only to you"}
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button 
            onClick={handleChangeVisibility}
            disabled={isChangeDisabled}
            style={{
              ...styles.button,
              ...(isChangeDisabled ? styles.disabledButton : {}),
            }}
          >
            {isLoading ? 'Changing...' : 'Change Visibility'}
          </button>
        </div>
        {error && <p style={styles.errorMessage}>{error}</p>}
        {successMessage && <p style={styles.successMessage}>{successMessage}</p>}
        
        {storyVisibility === 'Public' && dropDownVisibility === 'Public' && (
          <>
            <p style={styles.linkToShare}>Link to share:</p>
            <div style={styles.shareShowInputContainer}>
              <input 
                type="text" 
                value={publicLink}
                readOnly
                style={styles.copyLinkFormInput}
              />
              <button 
                onClick={handleCopyLink} 
                style={styles.copyButton}
                title="Copy link"
              >
                <Copy size={20} />
              </button>
            </div>
          </>
        )}
        {copySuccess && <p style={styles.successMessage}>Link copied to clipboard!</p>}
      </div>
    </div>
  );
}

export default ShareShowScreen;