// src/pages/DeleteShowScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

function DeleteShowScreen() {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { showId, showName, numberOfEpisodes } = location.state || {};

  const [confirmDelete, setConfirmDelete] = useState('No');

  useEffect(() => {
    if (!showId || !showName) {
      navigate('/shows-list');
    }
  }, [showId, showName, navigate]);

  const handleDelete = async () => {
    if (confirmDelete === 'Yes') {
      try {
        const response = await fetch(`${API_BASE_URL}/manageshows/delete-show`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: user.user_id, showId: showId }),
        });

        if (response.ok) {
          navigate('/shows-list');
        } else {
          throw new Error('Failed to delete show');
        }
      } catch (error) {
        console.error('Error deleting show:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  const handleCancel = () => {
    navigate('/episodes-list', { state: { showId: showId } });
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Delete Show</h1>
        <p style={styles.showInfo}>{showName}</p>
        <p style={styles.showInfo}>{numberOfEpisodes} episodes</p>
        <p style={styles.showDescription}>Do you want to delete this show and all related content?</p>
        <div style={styles.formGroup}>
          <label htmlFor="confirmDelete" style={styles.label}>Confirm Deletion:</label>
          <select
            id="confirmDelete"
            value={confirmDelete}
            onChange={(e) => setConfirmDelete(e.target.value)}
            style={styles.select}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div style={styles.buttonContainer}>
          <button
            onClick={handleDelete}
            style={{
              ...styles.button,
              ...(confirmDelete !== 'Yes' ? styles.disabledButton : {}),
            }}
            disabled={confirmDelete !== 'Yes'}
          >
            Delete Show
          </button>
          <button onClick={handleCancel} style={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteShowScreen;