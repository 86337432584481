// src/components/NavBar.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const loginButtonStyle = {
    ...styles.button,
    padding: '8px 16px',
    marginRight: '16px',
    fontSize: '14px',
  };

  return (
    <nav style={styles.navBar}>
      <div 
        style={{...styles.logoText, cursor: 'pointer'}} 
        onClick={handleLogoClick}
      >
        KEMPO
      </div>
      <div style={styles.navBarRight}>
        {user.isAuthenticated ? (
          <span style={styles.welcomeMessage}>{user.userName}</span>
        ) : (
          <button 
            onClick={handleLoginClick} 
            style={loginButtonStyle}
          >
            Log In
          </button>
        )}
        <div style={styles.hamburgerIcon} onClick={toggleMenu}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </div>
      </div>
      {isOpen && (
        <ul style={styles.navBarList}>
          <li style={styles.navBarItem}>
            <Link to="/" style={styles.navBarLink} onClick={toggleMenu}>
              Home
            </Link>
          </li>
          {user.isAuthenticated ? (
            <>
              <li style={styles.navBarItem}>
                <Link to="/myaccount" style={styles.navBarLink} onClick={toggleMenu}>
                  My Account
                </Link>
              </li>
              <li style={styles.navBarItem}>
                <Link to="/logout" style={styles.navBarLink} onClick={toggleMenu}>
                  Logout
                </Link>
              </li>
              <li style={styles.navBarItem}>
                <Link to="/shows-list" style={styles.navBarLink} onClick={toggleMenu}>
                  Shows List
                </Link>
              </li>
            </>
          ) : (
            <>
              <li style={styles.navBarItem}>
                <Link to="/register" style={styles.navBarLink} onClick={toggleMenu}>
                  New User
                </Link>
              </li>
              <li style={styles.navBarItem}>
                <Link to="/login" style={styles.navBarLink} onClick={toggleMenu}>
                  Login
                </Link>
              </li>
            </>
          )}
        </ul>
      )}
    </nav>
  );
}

export default NavBar;