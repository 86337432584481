// src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import UserRegistrationScreen from './pages/UserRegistrationScreen';
import EmailVerificationScreen from './pages/EmailVerificationScreen';
import UserLoginScreen from './pages/UserLoginScreen';
import PasswordResetRequest from './pages/PasswordResetRequest';
import MyAccountScreen from './pages/MyAccountScreen';
import ChangePasswordScreen from './pages/ChangePasswordScreen';
import UserLogoutScreen from './pages/UserLogoutScreen';
import DeleteAccountScreen from './pages/DeleteAccountScreen';
import AIChatScreen from './pages/AIChatScreen';
import CreateShowScreen from './pages/CreateShowScreen';
import CompleteShowScreen from './pages/CompleteShowScreen';
import EpisodeDetailsScreen from './pages/EpisodeDetailsScreen';
import EpisodesListScreen from './pages/EpisodesListScreen';
import ShowsListScreen from './pages/ShowsListScreen';
import DeleteShowScreen from './pages/DeleteShowScreen';
import CreateVoiceScreen from './pages/CreateVoiceScreen';
import CompleteVoiceScreen from './pages/CompleteVoiceScreen';
import UserNameWebHome from './pages/UserNameWebHome';
import UserNameWebShow from './pages/UserNameWebShow';
import UserNameWebEpisode from './pages/UserNameWebEpisode';
import ShareShowScreen from './pages/ShareShowScreen';
import NavBar from './components/NavBar';
import { UserProvider } from './UserContext';
import { styles } from './styles';

function App() {
  return (
    <UserProvider>
      <div style={styles.app}>
        <NavBar />
        <main style={styles.mainContent}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<UserRegistrationScreen />} />
            <Route path="/emailverification" element={<EmailVerificationScreen />} />
            <Route path="/login" element={<UserLoginScreen />} />
            <Route path="/passwordresetrequest" element={<PasswordResetRequest />} />
            <Route path="/myaccount" element={<MyAccountScreen />} />
            <Route path="/changepassword" element={<ChangePasswordScreen />} />
            <Route path="/logout" element={<UserLogoutScreen />} />
            <Route path="/deleteaccount" element={<DeleteAccountScreen />} />
            <Route path="/aichat" element={<AIChatScreen />} />
            <Route path="/create-show" element={<CreateShowScreen />} />
            <Route path="/complete-show" element={<CompleteShowScreen />} />
            <Route path="/episode-details" element={<EpisodeDetailsScreen />} />
            <Route path="/episodes-list" element={<EpisodesListScreen />} />
            <Route path="/shows-list" element={<ShowsListScreen />} />
            <Route path="/delete-show" element={<DeleteShowScreen />} />
            <Route path="/create-voice" element={<CreateVoiceScreen />} />
            <Route path="/complete-voice" element={<CompleteVoiceScreen />} />
            <Route path="/share-show" element={<ShareShowScreen />} />
            <Route path="/user/:viewUserName" element={<UserNameWebHome />} />
            <Route path="/user/:userId/show/:showId" element={<UserNameWebShow />} />
            <Route path="/user/:userId/show/:showId/episode/:episodeId" element={<UserNameWebEpisode />} />
          </Routes>
        </main>
      </div>
    </UserProvider>
  );
}

export default App;