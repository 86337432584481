// src/pages/ShowsListScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Share2, Lock } from 'lucide-react';

function ShowsListScreen() {
  const { user } = useUser();
  const navigate = useNavigate();

  const [showsList, setShowsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchShows = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-shows`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: user.user_id }),
      });
      const data = await response.json();
      if (response.ok) {
        setShowsList(data.shows);
      } else {
        throw new Error(data.message || 'Failed to fetch shows.');
      }
    } catch (error) {
      console.error('Error fetching shows:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchShows();
    } else {
      navigate('/login');
    }
  }, [user.isAuthenticated, navigate]);

  const handleShowClick = (showId) => {
    navigate('/episodes-list', { state: { showId: showId } });
  };

  const handleShareClick = (e, show) => {
    e.stopPropagation();
    navigate('/share-show', { 
      state: { 
        showId: show.id, 
        userId: user.user_id, 
        visibility: show.visibility 
      } 
    });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.sectionHeader}>Your Shows</h1>
      <button onClick={() => navigate('/create-show')} style={styles.button}>
        Create New Show
      </button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div style={styles.gridContainer}>
          {showsList.map((show) => (
            <div 
              key={show.id} 
              style={styles.showCard}
              onClick={() => handleShowClick(show.id)}
            >
              <img 
                src={show.thumbnail_url || '/path/to/default/image.jpg'} 
                alt={show.name} 
                style={styles.showThumbnail}
              />
              <button
                style={styles.showNameList}
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowClick(show.id);
                }}
              >
                {show.name}
              </button>
              <div 
                style={styles.visibilityIcon}
                onClick={(e) => handleShareClick(e, show)}
              >
                {show.visibility === 'Public' ? (
                  <Share2 size={20} color="#007bff" />
                ) : (
                  <Lock size={20} color="#6c757d" />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ShowsListScreen;