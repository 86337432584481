// src/pages/MyAccountScreen.js

import React from 'react';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { Link } from 'react-router-dom';

function MyAccountScreen() {
  const { user } = useUser();

  return (
    <div style={styles.container}>
      <h1 style={styles.sectionHeader}>My Account</h1>
      <div style={styles.accountDetails}>
        <p><strong>User Name:</strong> {user.userName}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p>
          <Link to="/changepassword" style={styles.link}>Change Password</Link>
        </p>
        <p>
          <Link to="/deleteaccount" style={styles.link}>Delete Account</Link>
        </p>
      </div>
    </div>
  );
}

export default MyAccountScreen;