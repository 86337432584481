// src/pages/UserNameWebHome.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { styles } from '../styles';
import './UserNameWebShow.css';

function UserNameWebShow() {
  const { userId, showId } = useParams();
  const navigate = useNavigate();

  const [episodes, setEpisodes] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [error, setError] = useState(null);

  const fetchShowDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-user-show-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, showId: showId }),
      });
      const data = await response.json();
      if (response.ok) {
        setShowDetails(data);
      } else {
        throw new Error(data.error || 'Failed to fetch show details.');
      }
    } catch (error) {
      console.error('Error fetching show details:', error);
      setError(error.message);
    }
  };

  const fetchEpisodes = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-user-episodes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, showId: showId }),
      });
      const data = await response.json();
      if (response.ok) {
        setEpisodes(data.episodes);
      } else {
        throw new Error(data.error || 'Failed to fetch episodes.');
      }
    } catch (error) {
      console.error('Error fetching episodes:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchShowDetails();
    fetchEpisodes();
  }, [userId, showId]);

  const handleEpisodeClick = (episodeId) => {
    navigate(`/user/${userId}/show/${showId}/episode/${episodeId}`);
  };

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div>
      {showDetails && (
        <div style={styles.showDetailsContainer}>
          <img 
            src={showDetails.thumbnail_url} 
            alt={showDetails.name} 
            style={styles.showThumbnail}
          />
          <div style={styles.container}>
            <h1 style={styles.showTitle}>{showDetails.name}</h1>
            <p style={styles.showDescription}>{showDetails.description}</p>
            <p style={styles.showInfo}>
              {showDetails.numberOfEpisodes} episodes · {showDetails.episodeDuration} minutes each
            </p>
          </div>
        </div>
      )}
      <div style={styles.container}>
        <h3 style={styles.episodesHeader}>Episodes</h3>
        <div>
          {episodes.map((episode) => (
            <div 
              key={episode.id} 
              className="episode-container"
              onClick={() => handleEpisodeClick(episode.id)}
            >
              <div style={styles.episodeNumber}>{episode.episode_number}</div>
              <div style={styles.episodeContent}>
                <div className="episode-title" style={styles.episodeListTitle}>{episode.title}</div>
                <p className="episode-description" style={styles.episodeDescription}>{episode.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserNameWebShow;