// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7U7aAQPSkj3O9gSDxij7e3se_Yie7Lcc",
  authDomain: "imitator-4f6d8.firebaseapp.com",
  projectId: "imitator-4f6d8",
  storageBucket: "imitator-4f6d8.appspot.com",
  messagingSenderId: "957060529182",
  appId: "1:957060529182:web:41121f124016fe7ef6c20f",
  measurementId: "G-YSS4QZJPM5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get the authentication object
const auth = getAuth(app); // Use getAuth to get the auth object

// Export the auth object
export { auth }; // Make the auth object available for import