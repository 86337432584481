// src/pages/UserNameWebEpisode.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { styles } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faFastForward, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../config';

function UserNameWebEpisode() {
  const { userId, showId, episodeId } = useParams();
  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(null);
  const [episodeDetails, setEpisodeDetails] = useState(null);
  const [episodeAudio, setEpisodeAudio] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showTranscript, setShowTranscript] = useState(false);
  const [episodeCount, setEpisodeCount] = useState(0);

  const progressBarRef = useRef(null);

  useEffect(() => {
    const fetchShowDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-user-show-details`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId, showId }),
        });
        const data = await response.json();
        if (response.ok) {
          setShowDetails(data);
          setEpisodeCount(Number(data.numberOfEpisodes));
        } else {
          throw new Error(data.error || 'Failed to fetch show details.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching show details: ${error.message}`);
      }
    };

    const fetchEpisodeDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-user-episode-detail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId, showId, episodeId }),
        });
        const data = await response.json();
        if (response.ok) {
          setEpisodeDetails({
            ...data,
            episode_number: Number(data.episode_number)
          });
        } else {
          throw new Error(data.error || 'Failed to fetch episode details.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching episode details: ${error.message}`);
      }
    };

    const fetchEpisodeAudio = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-user-episode-audio`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId, showId, episodeId }),
        });
        const data = await response.json();
        if (response.ok) {
          setEpisodeAudio(data.audio_url || null);
          const audio = new Audio(data.audio_url || '');
          setAudioElement(audio);
          audio.addEventListener('loadedmetadata', () => {
            setDuration(audio.duration);
          });
          audio.addEventListener('timeupdate', () => {
            setCurrentTime(audio.currentTime);
          });
          setErrorMessage('');
        } else {
          throw new Error(data.error || 'Failed to fetch episode audio.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching episode audio: ${error.message}`);
        setEpisodeAudio(null);
      }
    };

    fetchShowDetails();
    fetchEpisodeDetails();
    fetchEpisodeAudio();

  }, [userId, showId, episodeId]);

  useEffect(() => {
    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    };
  }, [audioElement]);

  const handleTogglePlayPause = async () => {
    if (!audioElement) return;

    try {
      if (isPlaying) {
        audioElement.pause();
        setIsPlaying(false);
      } else {
        await audioElement.play();
        setIsPlaying(true);
      }
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(`Error during audio playback: ${error.message}`);
    }
  };

  const handleProgressBarClick = (e) => {
    if (!audioElement || !progressBarRef.current) return;

    const progressBar = progressBarRef.current;
    const clickPosition = (e.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
    const newTime = clickPosition * duration;

    audioElement.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleNextEpisode = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-next-episode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: userId, showId, episodeId }),
      });
      const data = await response.json();
      
      if (response.ok && data.nextEpisodeId) {
        navigate(`/user/${userId}/show/${showId}/episode/${data.nextEpisodeId}`);
      } else if (response.status === 204) {
        setErrorMessage('This is the last episode of the show.');
      } else {
        throw new Error(data.error || 'Failed to fetch next episode.');
      }
    } catch (error) {
      setErrorMessage(`Error fetching next episode: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.linkContainer, textAlign: 'left', marginBottom: '20px' }}>
        <Link to={`/user/${userId}/show/${showId}`} style={styles.link}>
          &lt;&lt; Back to Episodes List
        </Link>
      </div>
      {errorMessage && <p style={styles.error}>{errorMessage}</p>}
      {showDetails && (
        <div style={styles.showDetailsContainer}>
          <img 
            src={showDetails.thumbnail_url} 
            alt={showDetails.name} 
            style={styles.showThumbnail}
          />
        </div>
      )}
      {episodeDetails && (
        <div>
          {episodeCount === 1 ? (
            <>
              <h1 style={styles.showTitle}>{showDetails?.name}</h1>
              <h2 style={styles.showDescription}>{showDetails?.description}</h2>
            </>
          ) : (
            <>
              <h1 style={styles.episodeTitle}>Episode {episodeDetails.episode_number}: {episodeDetails.title}</h1>
              <h2 style={styles.showNameDetails}>{showDetails?.name}</h2>
            </>
          )}
          <div style={{ ...styles.audioControlsContainer, flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '10px' }}>
              <div 
                ref={progressBarRef}
                style={styles.progressBar}
                onClick={handleProgressBarClick}
              >
                <div 
                  style={{
                    ...styles.progressBarFill,
                    width: `${(currentTime / duration) * 100}%`
                  }}
                />
              </div>
              <span style={styles.timeDisplay}>
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <button
                onClick={handleTogglePlayPause}
                disabled={!episodeAudio}
                style={styles.playPauseButton}
              >
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </button>
              {episodeDetails.episode_number < episodeCount && (
                <button
                  style={styles.nextEpisodeButton}
                  onClick={handleNextEpisode}
                >
                  <FontAwesomeIcon icon={faFastForward} />
                </button>
              )}
            </div>
          </div>
          {episodeCount === 1 
            ? <p></p>
            : <p style={styles.episodeDescription}>{episodeDetails.description}</p>
          }
          <div style={styles.transcriptContainer}>
            <h3 
              style={styles.transcriptHeader}
              onClick={() => setShowTranscript(!showTranscript)}
            >
              Transcript 
              <FontAwesomeIcon icon={showTranscript ? faChevronDown : faChevronUp} style={styles.transcriptIcon} />
            </h3>
            {showTranscript && (
              <p style={styles.transcriptText}>{episodeDetails.script}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserNameWebEpisode;