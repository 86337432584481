// src/pages/DeleteAccountScreen.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Adjust import path as needed
import { styles } from '../styles';
import { useUser } from '../UserContext'; // Import useUser
import { EmailAuthProvider, reauthenticateWithCredential, deleteUser } from "firebase/auth";
import { Eye, EyeOff } from 'lucide-react';
import { API_BASE_URL } from '../config';

function PasswordInput({ value, onChange, required }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={styles.inputContainer}>
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        style={styles.formInput}
        required={required}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        style={styles.eyeIcon}
      >
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
}

function DeleteAccountScreen() {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('No');
  const [error, setError] = useState('');
  const { user } = useUser(); // Get user details from context
  const { updateUser } = useUser(); // Get updateUser function from context
  const navigate = useNavigate();

  // Redirect if not logged in
  if (!auth.currentUser) {
    navigate('/login');
    return null;
  }

  const handleDeleteAccount = async (e) => {
    e.preventDefault();

    if (confirmation !== 'Yes' || !password) {
      setError('Please select "Yes" to confirm and enter your password.');
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in');
      }

      // Reauthenticate user
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // Get ID Token
      const idToken = await user.getIdToken(true);

      // Make the DELETE request to the backend
      const response = await fetch(`${API_BASE_URL}/auth/delete-account`, {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ uid: user.uid }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete account on the server');
      }

      // Delete the user from Firebase Authentication
      await deleteUser(user);

      // Update the user context to reflect that the user is no longer authenticated
      updateUser({
        isAuthenticated: false,
        name: null,
        email: null,
        user_id: null,
      });

      alert('Account deleted successfully.');
      navigate('/logout');
    } catch (error) {
      console.error('Account deletion error:', error);
      setError(error.message || 'An error occurred during account deletion. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/myaccount');
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Delete Account</h1>
        <p>Are you sure you want to delete the account for {user.email}?</p>
        <form onSubmit={handleDeleteAccount} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="confirmation" style={styles.label}>Confirmation:</label>
            <select
              id="confirmation"
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              style={styles.formInput}
              required
            >
              <option value="No">No, do not delete</option>
              <option value="Yes">Yes, delete this account</option>
            </select>
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Enter password to delete:</label>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.button}>Delete Account</button>
            <button type="button" onClick={handleCancel} style={styles.cancelButton}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeleteAccountScreen;