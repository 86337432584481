// src/pages/UserRegistrationScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { styles } from '../styles';
import { Eye, EyeOff, Check } from 'lucide-react';
import { API_BASE_URL } from '../config';

function PasswordRequirement({ isMet, children, isEmpty }) {
  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      color: isEmpty ? 'grey' : (isMet ? 'green' : 'red'), 
      fontSize: '0.8rem',
      marginTop: '0.25rem'
    }}>
      {isMet && <Check size={16} style={{ marginRight: '0.25rem' }} />}
      {children}
    </div>
  );
}

function PasswordInput({ value, onChange, required, onValidityChange }) {
  const [showPassword, setShowPassword] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isComplexityValid, setIsComplexityValid] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setIsEmpty(value.length === 0);
    const lengthValid = value.length >= 8 && value.length <= 20;
    const complexityValid = /[A-Za-z]/.test(value) && /\d/.test(value) && /[@$!%*#?&]/.test(value);
    
    setIsLengthValid(lengthValid);
    setIsComplexityValid(complexityValid);
    
    onValidityChange(lengthValid && complexityValid);
  }, [value, onValidityChange]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={styles.inputContainer}>
        <input
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          style={{...styles.formInput, flex: 1}}
          required={required}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          style={styles.eyeIcon}
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
      </div>
      <PasswordRequirement isMet={isLengthValid} isEmpty={isEmpty}>
        Password must have 8-20 characters
      </PasswordRequirement>
      <PasswordRequirement isMet={isComplexityValid} isEmpty={isEmpty}>
        Password must have letters, numbers, and special characters
      </PasswordRequirement>
    </>
  );
}

function UserRegistrationScreen() {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isUserNameAvailable, setIsUserNameAvailable] = useState(null);
  const [userNameSuggestion, setUserNameSuggestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const MAX_USERNAME_LENGTH = 24;

  const handleUserNameChange = (e) => {
    const input = e.target.value.toLowerCase();
    const sanitizedInput = input.replace(/[^a-z0-9_.]/g, '').slice(0, MAX_USERNAME_LENGTH);
    setUserName(sanitizedInput);
    setUserNameSuggestion('');  // Clear suggestion when user types
  };

  useEffect(() => {
    const checkUserNameAvailability = async () => {
      if (userName.length > 0 && userName.length <= MAX_USERNAME_LENGTH) {
        try {
          const response = await fetch(`${API_BASE_URL}/auth/check-username`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName })
          });

          if (!response.ok) {
            throw new Error('Failed to check username availability.');
          }

          const data = await response.json();
          setIsUserNameAvailable(data.available);
          if (!data.available && data.suggestion) {
            setUserNameSuggestion(data.suggestion);
          } else {
            setUserNameSuggestion('');
          }
        } catch (error) {
          console.error('Username check error:', error);
          setIsUserNameAvailable(false);
          setUserNameSuggestion('');
        }
      } else {
        setIsUserNameAvailable(null);
        setUserNameSuggestion('');
      }
    };

    const debounceTimer = setTimeout(() => {
      checkUserNameAvailability();
    }, 500); // Wait for 500ms after the user stops typing

    return () => clearTimeout(debounceTimer);
  }, [userName]);

  const handleSuggestionClick = () => {
    if (userNameSuggestion) {
      setUserName(userNameSuggestion);
      setUserNameSuggestion('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!isPasswordValid || !isUserNameAvailable || userName.length > MAX_USERNAME_LENGTH) {
      setError('Please ensure all fields are valid.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, email, password })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to register user.');
      }

      console.log(data);
      navigate('/emailverification');

    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message || 'An error occurred during registration.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordValidityChange = (isValid) => {
    setIsPasswordValid(isValid);
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Register as new user</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="userName" style={styles.label}>User Name:</label>
            <div style={styles.inputContainer}>
              <input
                type="text"
                id="userName"
                value={userName}
                onChange={handleUserNameChange}
                style={styles.formInput}
                required
              />
            </div>
            <div style={styles.feedbackGrey}>
              User names can only have lower case letters, numbers, underscores and periods.
            </div>
            {userName.length > MAX_USERNAME_LENGTH && (
              <div style={styles.feedbackRed}>
                User names must be 24 characters or less.
              </div>
            )}
            {userName.length > 0 && userName.length <= MAX_USERNAME_LENGTH && isUserNameAvailable !== null && (
              <div style={isUserNameAvailable ? styles.feedbackGreen : styles.feedbackRed}>
                {isUserNameAvailable ? 'User name is available' : 'User name is taken'}
                {!isUserNameAvailable && userNameSuggestion && (
                  <span>
                    . Try <button 
                      onClick={handleSuggestionClick}
                      style={styles.suggestionLink}
                    >
                      {userNameSuggestion}
                    </button> instead?
                  </span>
                )}
              </div>
            )}
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>Email:</label>
            <div style={styles.inputContainer}>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.formInput}
                required
              />
            </div>
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Password:</label>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
              onValidityChange={handlePasswordValidityChange}
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <button 
            type="submit" 
            style={{
              ...styles.button,
              ...(isPasswordValid && isUserNameAvailable && userName.length <= MAX_USERNAME_LENGTH ? {} : styles.disabledButton)
            }}
            disabled={!isPasswordValid || !isUserNameAvailable || userName.length > MAX_USERNAME_LENGTH || isLoading}
          >
            {isLoading ? 'Registering...' : 'Register'}
          </button>
        </form>
        <p style={{ marginTop: '20px' }}>
          Existing user? <Link to="/login" style={styles.link}>Login here</Link>
        </p>
      </div>
    </div>
  );
}

export default UserRegistrationScreen;