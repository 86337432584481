// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './animations.css';  // Import the CSS file globally
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { styles } from './styles';

// Force HTTPS in production
if (process.env.NODE_ENV === 'production' && window.location.protocol === 'http:') {
  window.location.href = window.location.href.replace('http:', 'https:');
}


const root = ReactDOM.createRoot(document.getElementById('root'));

const GlobalStyle = () => (
  <style>
    {`
      body {
        margin: 0;
        font-family: ${styles.body.fontFamily};
        background-color: ${styles.body.backgroundColor};
      }
      code {
        font-family: ${styles.code.fontFamily};
      }
    `}
  </style>
);

root.render(
  <React.StrictMode>
    <Router>
      <GlobalStyle />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);