// src/pages/AIChatScreen.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

function AIChatScreen() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const chatEndRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
    // Adjust textarea height
    event.target.style.height = 'auto';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!input.trim()) return;

    setIsSubmitting(true);
    const userMessage = { role: 'user', content: input };
    const updatedChatHistory = [...chatHistory, userMessage];
    setChatHistory(updatedChatHistory);
    setInput('');
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
    }

    try {
      const response = await fetch(`${API_BASE_URL}/ai-chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: updatedChatHistory })
      });

      if (!response.ok) {
        throw new Error('Failed to get AI response');
      }

      const data = await response.json();
      const aiMessage = { role: 'assistant', content: data.response };
      setChatHistory(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('AI chat error:', error);
      // Optionally add an error message to chat history
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.chatHistory}>
        {chatHistory.map((message, index) => (
          <div key={index} style={message.role === 'user' ? styles.userMessage : styles.aiMessage}>
            {message.content}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
      <form onSubmit={handleSubmit} style={styles.chatForm}>
        <textarea
          ref={textareaRef}
          value={input}
          onChange={handleInputChange}
          style={styles.chatInput}
          placeholder="Type your message..."
          disabled={isSubmitting}
        />
        <button 
          type="submit" 
          style={{...styles.chatSubmitButton, ...(isSubmitting ? styles.disabledButton : {})}} 
          disabled={isSubmitting}
        >
          ↑
        </button>
      </form>
    </div>
  );
}

export default AIChatScreen;