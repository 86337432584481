// src/pages/ChangePasswordScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Adjust import path as needed
import { styles } from '../styles';
import { Eye, EyeOff, Check } from 'lucide-react';

function PasswordRequirement({ isMet, children, isEmpty }) {
  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      color: isEmpty ? 'grey' : (isMet ? 'green' : 'red'), 
      fontSize: '0.8rem',
      marginTop: '0.25rem'
    }}>
      {isMet && <Check size={16} style={{ marginRight: '0.25rem' }} />}
      {children}
    </div>
  );
}

function PasswordInput({ value, onChange, required, placeholder, onValidityChange, showRequirements }) {
  const [showPassword, setShowPassword] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isComplexityValid, setIsComplexityValid] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setIsEmpty(value.length === 0);
    const lengthValid = value.length >= 8 && value.length <= 20;
    const complexityValid = /[A-Za-z]/.test(value) && /\d/.test(value) && /[@$!%*#?&]/.test(value);
    
    setIsLengthValid(lengthValid);
    setIsComplexityValid(complexityValid);
    
    if (onValidityChange) {
      onValidityChange(lengthValid && complexityValid);
    }
  }, [value, onValidityChange]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={styles.inputContainer}>
        <input
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          style={styles.formInput}
          required={required}
          placeholder={placeholder}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          style={styles.eyeIcon}
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
      </div>
      {showRequirements && (
        <>
          <PasswordRequirement isMet={isLengthValid} isEmpty={isEmpty}>
            Password must have 8-20 characters
          </PasswordRequirement>
          <PasswordRequirement isMet={isComplexityValid} isEmpty={isEmpty}>
            Password must have letters, numbers, and special characters
          </PasswordRequirement>
        </>
      )}
    </>
  );
}

function ChangePasswordScreen() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if not logged in
    if (!auth.currentUser) {
      navigate('/login');
    }
  }, [navigate]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (!isNewPasswordValid) {
      setError('Please ensure the new password meets all requirements.');
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);

      await updatePassword(auth.currentUser, newPassword);
      navigate('/'); // Redirect to Home page after successful password update
      alert('Password change was successful.');
    } catch (error) {
      setError('Old password incorrect. ' + (error.message || ''));
    }
  };

  const handleNewPasswordValidityChange = (isValid) => {
    setIsNewPasswordValid(isValid);
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Change Password</h1>
        <form onSubmit={handlePasswordChange} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="oldPassword" style={styles.label}>Old Password:</label>
            <PasswordInput
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required={true}
              placeholder="Enter your current password"
              showRequirements={false}
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="newPassword" style={styles.label}>New Password:</label>
            <PasswordInput
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required={true}
              placeholder="Enter your new password"
              onValidityChange={handleNewPasswordValidityChange}
              showRequirements={true}
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <button 
            type="submit" 
            style={{
              ...styles.button,
              ...(isNewPasswordValid ? {} : styles.disabledButton)
            }}
            disabled={!isNewPasswordValid}
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordScreen;