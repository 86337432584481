// src/pages/UserLogoutScreen.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Adjust the import path as needed
import { signOut } from 'firebase/auth';
import { useUser } from '../UserContext'; // Import useUser
import { styles } from '../styles';

function UserLogoutScreen() {
  const { updateUser } = useUser(); // Get updateUser from context
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await signOut(auth);
        // Reset user context to initial state
        updateUser({
          isAuthenticated: false,
          name: null,
          lastName: null,
          user_id: null,
        });

        // Navigate to the login page or home page after successful logout
        navigate('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
    };

    handleLogout();
  }, [updateUser, navigate]);

  return (
    <div style={styles.registrationContainer}>
      <h1 style={styles.sectionHeader}>Logging Out...</h1>
      {/* Optionally, show a spinner or message while logging out */}
    </div>
  );
}

export default UserLogoutScreen;