// src/pages/CompleteVoiceScreen.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const CompleteVoiceScreen = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { voiceDescription, sampleText } = location.state || {};
  const [isCreating, setIsCreating] = useState(false);
  const [statusMessages, setStatusMessages] = useState([]);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const creationAttempted = useRef(false);

  const createVoice = async () => {
    if (creationAttempted.current) return;
    creationAttempted.current = true;

    try {
      setIsCreating(true);
      setError(null);
      const response = await fetch(`${API_BASE_URL}/createvoices/create-voice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          voice_description: voiceDescription,
          sample_text: sampleText,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decodedChunk = decoder.decode(value, { stream: true });
        const lines = decodedChunk.split('\n');
        lines.forEach(line => {
          if (line.trim() !== '') {
            try {
              const parsed = JSON.parse(line);
              if (parsed.error) {
                setError(parsed.error);
              } else if (parsed.status === "Search result") {
                setSearchResult({ title: parsed.title, link: parsed.link });
              } else if (parsed.status) {
                setStatusMessages(prev => [...prev, parsed.status]);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        });
      }

      // Process completed
      setIsComplete(true);
    } catch (error) {
      console.error('Error creating voice:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      if (voiceDescription && sampleText) {
        createVoice();
      } else {
        navigate('/create-voice');
      }
    } else {
      navigate('/login');
    }
  }, [user.isAuthenticated, navigate, voiceDescription, sampleText]);

  return (
    <div style={styles.container}>
      <h1 style={styles.sectionHeader}>Creating Your Voice</h1>
      {isCreating && <p>Creating your new voice. Please be patient...</p>}
      {statusMessages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
      {searchResult && (
        <div>
          <h2>Search Result:</h2>
          <p>{searchResult.title}</p>
          {searchResult.link && (
            <a href={searchResult.link} target="_blank" rel="noopener noreferrer" style={styles.link}>
              View Result
            </a>
          )}
        </div>
      )}
      {error && (
        <div style={styles.errorMessage}>
          <p>Error: {error}</p>
          <button 
            onClick={() => {
              creationAttempted.current = false;
              navigate('/create-voice');
            }} 
            style={styles.button}
          >
            Try Again
          </button>
        </div>
      )}
      {isComplete && (
        <div>
          <p>Voice creation complete!</p>
          <button 
            onClick={() => navigate('/voices-list')} 
            style={styles.button}
          >
            Go to Voices List
          </button>
        </div>
      )}
    </div>
  );
};

export default CompleteVoiceScreen;