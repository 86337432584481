// src/pages/PasswordResetRequest.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styles } from '../styles';
import { Eye, EyeOff, Check } from 'lucide-react';
import { API_BASE_URL } from '../config';

function PasswordRequirement({ isMet, children, isEmpty }) {
  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      color: isEmpty ? 'grey' : (isMet ? 'green' : 'red'), 
      fontSize: '0.8rem',
      marginTop: '0.25rem'
    }}>
      {isMet && <Check size={16} style={{ marginRight: '0.25rem' }} />}
      {children}
    </div>
  );
}

function PasswordInput({ value, onChange, required, onValidityChange }) {
  const [showPassword, setShowPassword] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isComplexityValid, setIsComplexityValid] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setIsEmpty(value.length === 0);
    const lengthValid = value.length >= 8 && value.length <= 20;
    const complexityValid = /[A-Za-z]/.test(value) && /\d/.test(value) && /[@$!%*#?&]/.test(value);
    
    setIsLengthValid(lengthValid);
    setIsComplexityValid(complexityValid);
    
    onValidityChange(lengthValid && complexityValid);
  }, [value, onValidityChange]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={styles.inputContainer}>
        <input
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          style={{...styles.formInput, flex: 1}}
          required={required}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          style={styles.eyeIcon}
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
      </div>
      <PasswordRequirement isMet={isLengthValid} isEmpty={isEmpty}>
        Password must have 8-20 characters
      </PasswordRequirement>
      <PasswordRequirement isMet={isComplexityValid} isEmpty={isEmpty}>
        Password must have letters, numbers, and special characters
      </PasswordRequirement>
    </>
  );
}

function PasswordResetRequest() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPasswordValid) {
      setMessage('Please ensure the password meets all requirements.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/auth/password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, new_password: newPassword }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage('Password reset successfully. Please verify your email.');
        navigate('/emailverification');
      } else {
        setMessage(result.error || 'Failed to reset password. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  const handlePasswordValidityChange = (isValid) => {
    setIsPasswordValid(isValid);
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.sectionHeader}>Reset Password</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>Email:</label>
            <div style={styles.inputContainer}>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.formInput}
                required
              />
            </div>
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="newPassword" style={styles.label}>New Password:</label>
            <PasswordInput
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required={true}
              onValidityChange={handlePasswordValidityChange}
            />
          </div>
          <button 
            type="submit" 
            style={{
              ...styles.button,
              ...(isPasswordValid ? {} : styles.disabledButton)
            }}
            disabled={!isPasswordValid}
          >
            Reset Password
          </button>
          {message && <p style={styles.error}>{message}</p>}
        </form>
      </div>
    </div>
  );
}

export default PasswordResetRequest;