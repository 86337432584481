// src/styles.js

const colors = {
  primary: '#007BFF',    // A vibrant blue
  secondary: '#6C757D',  // A muted gray
  accent: '#E50914',     // Netflix-inspired red accent
  background: '#141414', // Dark background inspired by Netflix
  text: '#FFFFFF',       // White text for dark background
  link: '#CCCCCC',       // Light grey for links
  linkHover: '#B0B0B0',  // Slightly darker grey for link hover state
  error: '#E50914',      // Netflix red for error messages
};

const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  h1: { fontSize: '2.5rem', fontWeight: 700 },
  h2: { fontSize: '2rem', fontWeight: 600 },
  h3: { fontSize: '1.5rem', fontWeight: 500 },
  body: { fontSize: '1rem', lineHeight: 1.5 },
};

const spacing = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
};

const shadows = {
  sm: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  md: '0 4px 6px rgba(0,0,0,0.1)',
  lg: '0 10px 20px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.10)',
};

export const styles = {
  app: {
    textAlign: 'center',
    fontFamily: typography.fontFamily,
    color: colors.text,
    backgroundColor: colors.background,
  },
  appHeader: {
    backgroundColor: colors.background,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: typography.body.fontSize,
    paddingTop: spacing.xl,
  },
  navBar: {
    backgroundColor: colors.background,
    padding: '20px',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    boxShadow: shadows.sm,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoText: {
    color: colors.primary,
    fontSize: '2em',
    fontWeight: 'bold',
    margin: 0,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  largeLogo: {
    color: colors.primary,
    fontSize: '4em',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(255,255,255,0.5)',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  navBarList: {
    listStyleType: 'none',
    padding: spacing.md,
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.sm,
    position: 'absolute',
    top: '60px',
    right: spacing.sm,
    backgroundColor: colors.secondary,
    borderRadius: '5px',
    width: 'auto',
    minWidth: '150px',
    boxShadow: shadows.md,
  },
  navBarItem: {
    display: 'block',
    width: '100%',
  },
  navBarLink: {
    ...typography.body,
    color: '#ffffff',
    textDecoration: 'none',
    padding: `${spacing.sm} ${spacing.md}`,
    borderRadius: '4px',
    transition: 'background-color 0.3s',
  },
  navBarLinkHover: {
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  navBarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  welcomeMessage: {
    color: '#ffffff',
    marginRight: '15px',
    fontSize: '0.9rem',
  },
  hamburgerIcon: {
    cursor: 'pointer',
    fontSize: '1.5em',
    color: '#ffffff',
  },
  mainContent: {
    marginTop: '80px',
    padding: '20px',
    backgroundColor: colors.background,
  },
  body: {
    margin: '0',
    fontFamily: typography.fontFamily,
    backgroundColor: colors.background,
  },
  code: {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: `0 ${spacing.md}`,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '50px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing.xl,
    backgroundColor: colors.background,
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: spacing.lg,
  },
  formGroup: {
    marginBottom: spacing.md,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  formInput: {
    padding: spacing.sm,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: `1px solid ${colors.secondary}`,
    width: '100%',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  eyeIcon: {
    position: 'absolute',
    right: spacing.sm,
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    color: colors.secondary,
  },
  eyeIconHover: {
    color: colors.primary,
  },
  label: {
    marginBottom: spacing.xs,
    fontSize: typography.body.fontSize,
    color: colors.text,
    alignSelf: 'flex-start',
  },
  textarea: {
    padding: spacing.sm,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: `1px solid ${colors.secondary}`,
    width: '100%',
    resize: 'vertical',
    minHeight: '100px',
    marginBottom: spacing.md,
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  select: {
    padding: spacing.sm,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: `1px solid ${colors.secondary}`,
    width: '100%',
    marginBottom: spacing.md,
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  button: {
    padding: `${spacing.sm} ${spacing.md}`,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: 'none',
    backgroundColor: colors.primary,
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  cancelButton: {
    padding: `${spacing.sm} ${spacing.md}`,
    fontSize: typography.body.fontSize,
    borderRadius: '4px',
    border: 'none',
    backgroundColor: colors.secondary,
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  disabledButton: {
    backgroundColor: colors.secondary,
    cursor: 'not-allowed',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing.md,
  },
  feedbackGrey: {
    color: 'grey',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  feedbackGreen: {
    color: 'green',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  feedbackRed: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  feedbackYellow: {
    color: 'yellow',
    fontSize: '0.8rem',
    marginTop: '0.25rem',
  },
  suggestionLink: {
    background: 'none',
    border: 'none',
    color: 'grey',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,
    font: 'inherit',
  },
  showItem: {
    marginVertical: spacing.sm,
    padding: spacing.md,
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: '5px',
    boxShadow: shadows.sm,
  },
  link: {
    color: colors.link,
    textDecoration: 'none',
    transition: 'color 0.3s, text-decoration 0.3s',
  },
  linkHover: {
    color: colors.linkHover,
    textDecoration: 'underline',
  },
  inputFocus: {
    borderColor: colors.primary,
    outline: 'none',
    boxShadow: '0 0 0 3px rgba(0,123,255,0.25)',
  },
  sectionHeader: {
    ...typography.h2,
    color: colors.text,
    marginBottom: spacing.lg,
  },
  error: {
    color: colors.error,
    backgroundColor: 'rgba(229, 9, 20, 0.1)',
    padding: spacing.sm,
    marginTop: spacing.md,
    marginBottom: spacing.md,
    borderRadius: '4px',
    fontSize: '0.9rem',
    width: '100%',
    textAlign: 'center',
  },
  chatContainer: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: spacing.md,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 80px)',
  },
  chatHistory: {
    flex: 1,
    overflowY: 'auto',
    marginBottom: spacing.md,
    padding: spacing.md,
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: '8px',
    boxShadow: shadows.sm,
  },
  userMessage: {
    textAlign: 'left',
    marginBottom: spacing.md,
    color: colors.text,
  },
  aiMessage: {
    textAlign: 'left',
    marginBottom: spacing.md,
    color: colors.text,
  },
  chatForm: {
    display: 'flex',
    position: 'relative',
  },
  chatInput: {
    flex: 1,
    padding: spacing.md,
    fontSize: typography.body.fontSize,
    borderRadius: '8px',
    border: `1px solid ${colors.secondary}`,
    resize: 'none',
    height: '100px',
    marginBottom: spacing.md,
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: colors.text,
  },
  chatSubmitButton: {
    position: 'absolute',
    right: spacing.xs,
    top: spacing.xs,
    width: '40px',
    height: '40px',
    backgroundColor: colors.primary,
    color: '#ffffff',
    border: 'none',
    borderRadius: '50%',
    fontSize: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s',
  },
  chatSubmitButtonHover: {
    backgroundColor: '#0056b3',
  },
  formTitle: {
    ...typography.h2,
    marginBottom: spacing.lg,
    color: colors.text,
  },
  stepContainer: {
    marginBottom: spacing.xl,
  },
  showList: {
    listStyleType: 'none',
    padding: 0,
    margin: `${spacing.lg} 0`,
  },
  showListItem: {
    marginBottom: spacing.md,
  },
  showLink: {
    ...typography.body,
    color: colors.link,
    textDecoration: 'none',
    padding: `${spacing.sm} ${spacing.md}`,
    display: 'block',
    borderRadius: '4px',
    transition: 'background-color 0.3s, color 0.3s',
    ':hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      color: colors.linkHover,
      textDecoration: 'underline',
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
    padding: '20px',
  },
  showCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  visibilityIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '50%',
    padding: '5px',
  },
  showThumbnail: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: spacing.md,
  },
  showNameList: {
    ...typography.body,
    color: colors.text, // This should be white based on your color scheme
    textDecoration: 'none',
    padding: '5px',
    margin: '0',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    ':hover': {
      color: colors.primary,
    },
  },
  contentContainer: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: `0 ${spacing.md}`,
  },
  notFoundMessage: {
    ...typography.h2,
    color: colors.text,
    textAlign: 'center',
  },
  episodesHeader: {
    ...typography.h3,
    color: colors.text,
    marginBottom: spacing.lg,
    textAlign: 'left',
  },
  episodeContainer: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
  },
  episodeNumber: {
    fontSize: '48px',
    fontWeight: 'bold',
    marginRight: '20px',
    minWidth: '60px',
    textAlign: 'center',
    color: colors.text,
  },
  episodeContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  episodeListTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    textAlign: 'left',
    display: 'block',
    width: '100%',
    color: colors.link,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  episodeDescription: {
    ...typography.body,
    color: colors.text,
    marginTop: spacing.md,
    marginBottom: spacing.lg,
    lineHeight: '1.6',
    textAlign: 'left',
    width: '100%',
  },
  episodeTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    textAlign: 'left',
    display: 'block',
    width: '100%',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    color: colors.link,
    textDecoration: 'none',
  },
  showDetailsContainer: {
    width: '100%',
    marginBottom: spacing.xl,
  },
  showTitle: {
    ...typography.h1,
    color: colors.text,
    marginBottom: spacing.sm,
  },
  showNameDetails: {
    ...typography.h2,
    color: colors.secondary, // This is the grey color
    marginBottom: spacing.lg,
  },
  showDescription: {
    ...typography.body,
    color: colors.text,
    marginBottom: spacing.md,
  },
  showInfo: {
    ...typography.body,
    color: colors.secondary,
    marginBottom: spacing.lg,
  },
  audioControlsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing.lg,
  },
  playPauseButton: {
    backgroundColor: colors.primary,
    color: '#ffffff',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nextEpisodeButton: {
    backgroundColor: colors.primary,
    color: '#ffffff',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBar: {
    flex: 1,
    height: '10px',
    backgroundColor: colors.secondary,
    marginLeft: spacing.md,
    marginRight: spacing.md,
    borderRadius: '5px',
    cursor: 'pointer',
  },
  progressBarFill: {
    height: '100%',
    backgroundColor: colors.primary,
    borderRadius: '5px',
  },
  timeDisplay: {
    ...typography.body,
    color: colors.text,
  },
  transcriptContainer: {
    marginTop: spacing.xl,
  },
  transcriptHeader: {
    ...typography.h3,
    color: colors.text,
    marginBottom: spacing.lg,
    textAlign: 'left',
  },
  transcriptIcon: {
    marginLeft: spacing.sm,
    color: colors.text,
  },
  transcriptText: {
    ...typography.body,
    color: colors.text,
    marginTop: spacing.md,
    textAlign: 'left',
  },
  episodeTitle: {
    ...typography.h1,
    color: colors.text,
    marginBottom: spacing.sm,
  },
  showName: {
    ...typography.h2,
    color: colors.secondary,
    marginBottom: spacing.lg,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#007bff',
    fontSize: '16px',
    marginBottom: '20px',
  },
  copyButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  privateMessage: {
    marginTop: '10px',
    color: '#6c757d',
    fontStyle: 'italic',
  },
  shareShowName: {
    fontSize: '1.5em',
    marginBottom: '20px',
    color: '#333',
  },
  shareShowInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    width: '100%', // Make it full width
  },
  copyLinkFormInput: {
    flexGrow: 1,
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginRight: '10px',
    width: 'calc(100% - 50px)', // Adjust width to account for the copy button
  },
  copyButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    flexShrink: 0, // Prevent the button from shrinking
  },
  linkToShare: {
    marginTop: '20px',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  topVideoContainer: {
    width: '100%',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  topVideoPlayer: {
    width: '100%',
    maxWidth: '1280px',  // Adjust this value as needed
    height: 'auto',
  },
};
export default styles;