// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styles } from '../styles';
import '../animations.css';

const Home = () => {
  const [showLogo, setShowLogo] = useState(false);
  const [fadeOutLogo, setFadeOutLogo] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    let animationShown = false;
    
    try {
      animationShown = sessionStorage.getItem('animationShown') === 'true';
    } catch (error) {
      // Silently handle the error
      // SessionStorage is not available, we'll default to showing the animation
    }

    if (!animationShown) {
      setShowLogo(true);
      try {
        sessionStorage.setItem('animationShown', 'true');
      } catch (error) {
        // Silently handle the error
        // Unable to set sessionStorage, but we can still show the animation
      }

      const fadeOutTimer = setTimeout(() => setFadeOutLogo(true), 3000);
      const logoTimer = setTimeout(() => setShowLogo(false), 5000);
      const contentTimer = setTimeout(() => setShowContent(true), 5500);

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(logoTimer);
        clearTimeout(contentTimer);
      };
    } else {
      setShowContent(true);
    }
  }, []);

  const logoLetters = "KEMPO".split('');

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      {showLogo && (
        <div className={`text-center ${fadeOutLogo ? 'logo-fade-out' : ''}`}>
          <div className="flex justify-center">
            {logoLetters.map((letter, index) => (
              <span
                key={index}
                className="letter-animation inline-block"
                style={{
                  ...styles.logoText,
                  fontSize: 'clamp(2rem, 10vw, 4rem)',
                  animation: `dropLetter 0.5s ease-out forwards ${index * 0.2}s`
                }}
              >
                {letter}
              </span>
            ))}
          </div>
        </div>
      )}
      {showContent && (
        <div className="content-animation" style={{ marginTop: '30vh' }}>
          <h1 style={styles.sectionHeader}>Build great content - fast and easy!</h1>
          <p>
            New user?{' '}
            <Link to="/register" style={styles.link}>Register here</Link>
          </p>
          <p>
            Existing user?{' '}
            <Link to="/login" style={styles.link}>Login here</Link>
          </p>
        </div>
      )}
    </div>
  );
}

export default Home;