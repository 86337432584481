// src/pages/EpisodesListScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import './EpisodesListScreen.css';

function EpisodesListScreen() {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { showId } = location.state || {};

  const [episodes, setEpisodes] = useState([]);
  const [showDetails, setShowDetails] = useState(null);

  const fetchShowDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-show-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: user.user_id, showId: showId }),
      });
      const data = await response.json();
      if (response.ok) {
        setShowDetails(data);
      } else {
        throw new Error(data.message || 'Failed to fetch show details.');
      }
    } catch (error) {
      console.error('Error fetching show details:', error);
    }
  };

  const fetchEpisodes = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-episodes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: user.user_id, showId: showId }),
      });
      const data = await response.json();
      if (response.ok) {
        setEpisodes(data.episodes);
      } else {
        throw new Error(data.message || 'Failed to fetch episodes.');
      }
    } catch (error) {
      console.error('Error fetching episodes:', error);
    }
  };

  useEffect(() => {
    if (!showId) {
      navigate('/shows-list');
      return;
    }

    if (user.isAuthenticated) {
      fetchShowDetails();
      fetchEpisodes();
    } else {
      navigate('/login');
    }
  }, [user.isAuthenticated, showId, navigate]);

  const handleDeleteShow = () => {
    navigate('/delete-show', { 
      state: { 
        showId: showId, 
        showName: showDetails.name, 
        numberOfEpisodes: showDetails.numberOfEpisodes 
      } 
    });
  };

  const handleEpisodeClick = (episodeId) => {
    navigate('/episode-details', { state: { showId: showId, episodeId: episodeId } });
  };

  return (
    <div>
      <div style={{ ...styles.linkContainer, textAlign: 'left', marginBottom: '20px' }}>
        <Link to="/shows-list" style={styles.link}>
          &lt;&lt; Back to Shows List
        </Link>
      </div>
      {showDetails && (
        <div style={styles.showDetailsContainer}>
          <img 
            src={showDetails.thumbnail_url} 
            alt={showDetails.name} 
            style={styles.showThumbnail}
          />
          <div style={styles.container}>
            <h1 style={styles.showTitle}>{showDetails.name}</h1>
            <p style={styles.showDescription}>{showDetails.description}</p>
            <p style={styles.showInfo}>
              {showDetails.numberOfEpisodes} episodes · {showDetails.episodeDuration} minutes each
            </p>
          </div>
        </div>
      )}
      <div style={styles.container}>
        <h3 style={styles.episodesHeader}>Episodes</h3>
        <div>
          {episodes.map((episode) => (
            <div 
              key={episode.id} 
              className="episode-container"
              onClick={() => handleEpisodeClick(episode.id)}
            >
              <div style={styles.episodeNumber}>{episode.episode_number}</div>
              <div style={styles.episodeContent}>
                <div className="episode-title" style={styles.episodeListTitle}>{episode.title}</div>
                <p className="episode-description" style={styles.episodeDescription}>{episode.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div style={styles.deleteShowContainer}>
          <button 
            onClick={handleDeleteShow} 
            style={styles.deleteShowLink}
          >
            Delete Show
          </button>
        </div>
      </div>
    </div>
  );
}

export default EpisodesListScreen;