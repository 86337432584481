// src/pages/CreateVoiceScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';

const CreateVoiceScreen = () => {
  const [voiceDescription, setVoiceDescription] = useState('');
  const [sampleText, setSampleText] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate('/login');
    }
  }, [user, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!voiceDescription.trim()) {
      alert('Please enter a voice description.');
      return;
    }

    if (!sampleText.trim()) {
      alert('Please enter sample text to speak.');
      return;
    }

    // Navigate to CompleteVoiceScreen with the form data
    navigate('/complete-voice', { 
      state: { 
        voiceDescription,
        sampleText
      } 
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h1 style={styles.formTitle}>Create Voice Description</h1>

        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor="voiceDescription" style={styles.label}>Voice Description</label>
            <textarea
              id="voiceDescription"
              style={styles.textarea}
              value={voiceDescription}
              onChange={(e) => setVoiceDescription(e.target.value)}
              placeholder="Enter voice description (e.g., 40-year-old man from the Northeast with a Boston accent)"
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="sampleText" style={styles.label}>Sample Text to Speak</label>
            <textarea
              id="sampleText"
              style={styles.textarea}
              value={sampleText}
              onChange={(e) => setSampleText(e.target.value)}
              placeholder="Enter sample text for the voice to speak"
            />
          </div>

          <div style={styles.buttonContainer}>
            <button 
              type="submit" 
              style={styles.button}
            >
              Create Voice
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateVoiceScreen;