// src/pages/CreateShowScreen.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../config';

const CreateShowScreen = () => {
  const [step, setStep] = useState(1);
  const [showName, setShowName] = useState('');
  const [showDescription, setShowDescription] = useState('');
  const [numberOfEpisodes, setNumberOfEpisodes] = useState('1');
  const [episodeDuration, setEpisodeDuration] = useState('1');
  const [showOptions, setShowOptions] = useState(false);
  const [voiceOption, setVoiceOption] = useState('echo');
  const [writingStyle, setWritingStyle] = useState('Conversational');
  const [audienceAge, setAudienceAge] = useState('Adults');
  const [audienceExpertise, setAudienceExpertise] = useState('General/Mass Audience');
  const [visibility, setVisibility] = useState('Public');
  const [isLoading, setIsLoading] = useState(false);
  const [research, setResearch] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();

  const voiceOptions = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
  const voiceDescriptions = {
    alloy: "Alloy has a warm gender neutral tone, making it suitable for general narrations and conversations.",
    echo: "Echo features a warm and clear male voice with a slight youthful tone. It has a modern and engaging sound, ideal for action oriented and energetic content.",
    fable: "Fable is a youthful British male voice. It has a storytelling vibe that works well for audiobooks and engaging narrations.",
    onyx: "Onyx has a deeper male voice with a more authoritative and calmer tone. Good for non-fiction, historical documentaries, and more serious content.",
    nova: "Nova is a female voice with a bright and clear tone. It has a friendly and approachable quality, making it great for lighter and more conversational content.",
    shimmer: "Shimmer has a adult female voice that exudes confidence and professionalism. It is ideal for general narration and expert advice."
  };

  const writingStyles = [
    "Analytical",
    "Conversational",
    "Creative/Imaginative",
    "Documentary/Investigative",
    "Educational/Informative",
    "Historical",
    "Humorous",
    "Inspirational/Motivational",
    "Narrative/Storytelling",
    "Technical/Instructional"
  ];

  const writingStyleDescriptions = {
    "Analytical": "In-depth and critical, examining topics from various angles and providing analysis and insights.",
    "Conversational": "Informal and friendly, written in a way that feels like a casual conversation with the reader.",
    "Creative/Imaginative": "Inventive and artistic, using creative language and ideas to present the content in a unique way.",
    "Documentary/Investigative": "Thorough and exploratory, delving into the subject with research and investigation.",
    "Educational/Informative": "Structured and factual, focusing on providing detailed information and explanations.",
    "Historical": "Contextual and descriptive, focusing on historical events, figures, and timelines.",
    "Humorous": "Light-hearted and funny, incorporating humor to make the content more entertaining.",
    "Inspirational/Motivational": "Uplifting and encouraging, designed to inspire and motivate the reader.",
    "Narrative/Storytelling": "Engaging and descriptive, using a narrative approach to convey the content through stories and anecdotes.",
    "Technical/Instructional": "Detailed and precise, focusing on step-by-step instructions and technical details."
  };

  const audienceAges = ["Children", "Teens/Young Adults", "Adults", "Seniors"];
  const audienceAgeDescriptions = {
    "Children": "Simplified language and engaging content suitable for younger audiences.",
    "Teens/Young Adults": "Content that appeals to teenagers and young adults, often addressing contemporary issues and relatable experiences.",
    "Adults": "General audience content, suitable for mature readers with a focus on a wide range of topics.",
    "Seniors": "Content suitable for older adults, often focusing on health, wellness, lifestyle, and nostalgia."
  };

  const audienceExpertises = ["General/Mass Audience", "Enthusiasts/Hobbyists", "Experts"];
  const audienceExpertiseDescriptions = {
    "General/Mass Audience": "Broad and accessible content with common, everyday language and vocabulary, designed for a wide range of readers regardless of their familiarity with the topic.",
    "Enthusiasts/Hobbyists": "Detailed and passionate insights with more precise terminology, targeting individuals familiar with specific interests, hobbies or some prior knowledge of the topic.",
    "Experts": "Advanced in-depth and scholarly content with more sophisticated language and advanced vocabulary regarding the topic, often designed for academic or professional purposes."
  };

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate('/login');
    }
  }, [user, navigate]);

  const handleNextStep = async () => {
    if (step === 1) {
      if (!showName.trim() || !showDescription.trim()) {
        alert('Please enter both a show name and description.');
        return;
      }
      
      setIsLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/createshows/set-story-defaults`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ showName, showDescription }),
        });

        if (!response.ok) {
          throw new Error('Failed to set story defaults');
        }

        const data = await response.json();
        if (data.writingStyle && writingStyles.includes(data.writingStyle)) {
          setWritingStyle(data.writingStyle);
        } else {
          console.warn('Received invalid writing style from backend, keeping default');
        }
        
        if (data.audienceAge && audienceAges.includes(data.audienceAge)) {
          setAudienceAge(data.audienceAge);
        } else {
          console.warn('Received invalid audience age from backend, keeping default');
        }

        if (data.audienceExpertise && audienceExpertises.includes(data.audienceExpertise)) {
          setAudienceExpertise(data.audienceExpertise);
        } else {
          console.warn('Received invalid audience expertise from backend, keeping default');
        }

        if (data.voice && voiceOptions.includes(data.voice)) {
          setVoiceOption(data.voice);
        } else {
          console.warn('Received invalid voice option from backend, keeping default');
        }
      } catch (error) {
        console.error('Error setting story defaults:', error);
        // You might want to show an error message to the user here
      } finally {
        setIsLoading(false);
      }
      
      setStep(2);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    navigate('/complete-show', { 
      state: { 
        showName, 
        showDescription, 
        numberOfEpisodes, 
        episodeDuration,
        voiceOption,
        voiceDescription: voiceDescriptions[voiceOption],
        writingStyle,
        writingStyleDescription: writingStyleDescriptions[writingStyle],
        audienceAge,
        audienceAgeDescription: audienceAgeDescriptions[audienceAge],
        audienceExpertise,
        audienceExpertiseDescription: audienceExpertiseDescriptions[audienceExpertise],
        visibility,
        research
      } 
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h1 style={styles.formTitle}>{step === 1 ? 'Create New Show' : 'Show Details'}</h1>

        <div style={styles.stepContainer}>
          {step === 1 ? (
            <>
              <div style={styles.formGroup}>
                <label htmlFor="showName" style={styles.label}>Show Name</label>
                <input
                  id="showName"
                  style={styles.formInput}
                  value={showName}
                  onChange={(e) => setShowName(e.target.value)}
                  placeholder="Enter Show Name"
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="showDescription" style={styles.label}>Show Description</label>
                <textarea
                  id="showDescription"
                  style={styles.textarea}
                  value={showDescription}
                  onChange={(e) => setShowDescription(e.target.value)}
                  placeholder="Enter Show Description"
                />
              </div>
              <button 
                style={styles.button} 
                onClick={handleNextStep}
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Next'}
              </button>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <div style={styles.formGroup}>
                <label htmlFor="numberOfEpisodes" style={styles.label}>Number of Episodes:</label>
                <select
                  id="numberOfEpisodes"
                  value={numberOfEpisodes}
                  style={styles.select}
                  onChange={(e) => setNumberOfEpisodes(e.target.value)}>
                  {[1, 2, 3, 4, 5, 6].map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div>

              <div style={styles.formGroup}>
                <label htmlFor="episodeDuration" style={styles.label}>Episode Duration (minutes):</label>
                <select
                  id="episodeDuration"
                  value={episodeDuration}
                  style={styles.select}
                  onChange={(e) => setEpisodeDuration(e.target.value)}>
                  {[1, 2, 3, 4, 5, 6, 7].map(duration => (
                    <option key={duration} value={duration}>{duration} minute{duration > 1 ? 's' : ''}</option>
                  ))}
                </select>
              </div>

              <div style={styles.formGroup}>
                <label htmlFor="visibility" style={styles.label}>Visibility:</label>
                <select
                  id="visibility"
                  value={visibility}
                  style={styles.select}
                  onChange={(e) => setVisibility(e.target.value)}
                >
                  <option value="Public">Public (recommended)</option>
                  <option value="Private">Private</option>
                </select>
                <p style={styles.visibilityDescription}>
                  {visibility === 'Public' 
                    ? "Visible on the web and shareable" 
                    : "Visible only to you"}
                </p>
              </div>

              <div style={styles.optionsSection}>
                <h3 
                  style={styles.optionsHeader} 
                  onClick={() => setShowOptions(!showOptions)}
                >
                  Options
                  <FontAwesomeIcon 
                    icon={showOptions ? faChevronDown : faChevronUp} 
                    style={styles.optionsIcon} 
                  />
                </h3>
                {showOptions && (
                  <>
                    <div style={styles.formGroup}>
                      <label htmlFor="writingStyle" style={styles.label}>Writing Style:</label>
                      <select
                        id="writingStyle"
                        value={writingStyle}
                        style={styles.select}
                        onChange={(e) => setWritingStyle(e.target.value)}
                      >
                        {writingStyles.map(style => (
                          <option key={style} value={style}>{style}</option>
                        ))}
                      </select>
                      {writingStyle && (
                        <p style={styles.styleDescription}>{writingStyleDescriptions[writingStyle]}</p>
                      )}
                    </div>
                    <div style={styles.formGroup}>
                      <label htmlFor="audienceAge" style={styles.label}>Audience Age:</label>
                      <select
                        id="audienceAge"
                        value={audienceAge}
                        style={styles.select}
                        onChange={(e) => setAudienceAge(e.target.value)}
                      >
                        {audienceAges.map(age => (
                          <option key={age} value={age}>{age}</option>
                        ))}
                      </select>
                      {audienceAge && (
                        <p style={styles.styleDescription}>{audienceAgeDescriptions[audienceAge]}</p>
                      )}
                    </div>
                    <div style={styles.formGroup}>
                      <label htmlFor="audienceExpertise" style={styles.label}>Audience Expertise:</label>
                      <select
                        id="audienceExpertise"
                        value={audienceExpertise}
                        style={styles.select}
                        onChange={(e) => setAudienceExpertise(e.target.value)}
                      >
                        {audienceExpertises.map(expertise => (
                          <option key={expertise} value={expertise}>{expertise}</option>
                        ))}
                      </select>
                      {audienceExpertise && (
                        <p style={styles.styleDescription}>{audienceExpertiseDescriptions[audienceExpertise]}</p>
                      )}
                    </div>
                    <div style={styles.formGroup}>
                      <label htmlFor="voiceOption" style={styles.label}>Voice:</label>
                      <select
                        id="voiceOption"
                        value={voiceOption}
                        style={styles.select}
                        onChange={(e) => setVoiceOption(e.target.value)}
                      >
                        {voiceOptions.map(voice => (
                          <option key={voice} value={voice}>{voice}</option>
                        ))}
                      </select>
                      {voiceOption && (
                        <p style={styles.voiceDescription}>{voiceDescriptions[voiceOption]}</p>
                      )}
                    </div>
                    <div style={styles.formGroup}>
                      <label style={styles.checkboxLabel}>
                        <input
                          type="checkbox"
                          checked={research}
                          onChange={(e) => setResearch(e.target.checked)}
                          style={styles.checkbox}
                        />
                        Research this on the web?
                      </label>
                    </div>
                  </>
                )}
              </div>

              <div style={styles.buttonContainer}>
                <button 
                  type="button" 
                  style={styles.cancelButton}
                  onClick={() => setStep(1)}
                >
                  Back
                </button>
                <button 
                  type="submit" 
                  style={styles.button}
                >
                  Create Show
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateShowScreen;