// src/config.js

// to test
// export const API_BASE_URL = 'https://imitatorback-064791c721f9.herokuapp.com';

// to deploy
export const API_BASE_URL = 'https://kempoaiback-b85f663ec3c9.herokuapp.com/';

// You can add other configuration variables here as needed
export const APP_NAME = 'Kempo';
export const VERSION = '1.0.0';