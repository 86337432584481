// src/pages/CompleteShowScreen.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

function CompleteShowScreen() {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    showName, 
    showDescription, 
    numberOfEpisodes, 
    episodeDuration, 
    voiceOption, 
    voiceDescription, 
    writingStyle, 
    writingStyleDescription,
    audienceAge,
    audienceAgeDescription,
    audienceExpertise,
    audienceExpertiseDescription,
    visibility,
    research
  } = location.state || {};
  
  const [isCreating, setIsCreating] = useState(false);
  const [statusMessages, setStatusMessages] = useState([]);
  const [error, setError] = useState(null);
  const [showId, setShowId] = useState(null);
  const [progress, setProgress] = useState(0);
  const creationAttempted = useRef(false);

  const createShow = async () => {
    if (creationAttempted.current) return;
    creationAttempted.current = true;

    try {
      setIsCreating(true);
      setError(null);

      // Step 1: Create Show ID
      setStatusMessages(prev => [...prev, 'Creating show ID...']);
      setProgress(5);
      const createShowResponse = await fetch(`${API_BASE_URL}/createshows/create-show-id`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: user.user_id,
          name: showName,
          description: showDescription,
          numberOfEpisodes: numberOfEpisodes,
          episodeDuration: episodeDuration,
          voiceOption: voiceOption,
          voiceDescription: voiceDescription,
          writingStyle: writingStyle,
          writingStyleDescription: writingStyleDescription,
          audienceAge: audienceAge,
          audienceAgeDescription: audienceAgeDescription,
          audienceExpertise: audienceExpertise,
          audienceExpertiseDescription: audienceExpertiseDescription,
          visibility: visibility,
          research: research
        }),
      });

      if (!createShowResponse.ok) {
        const errorData = await createShowResponse.json();
        throw new Error(errorData.message || 'Failed to create show ID');
      }

      const showData = await createShowResponse.json();
      setShowId(showData.new_show_id);
      setStatusMessages(prev => [...prev, 'Show ID created successfully.']);
      setProgress(10);

      // Step 2: Web Research (if applicable)
      if (research) {
        setStatusMessages(prev => [...prev, 'Starting web research...']);
        setProgress(15);
        const researchResponse = await fetch(`${API_BASE_URL}/createshows/web-research`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id: user.user_id,
            show_id: showData.new_show_id
          }),
        });

        if (!researchResponse.ok) {
          const errorData = await researchResponse.json();
          throw new Error(errorData.message || 'Failed to complete web research');
        }

        const researchData = await researchResponse.json();
        setStatusMessages(prev => [...prev, researchData.message]);

        if (researchData.status !== 'research_complete') {
          throw new Error('Unexpected research status');
        }

        setStatusMessages(prev => [...prev, 'Web research completed successfully. Preparing to create episode outlines...']);
        setProgress(20);
      }

      // Step 3: Create Episode Outlines
      setStatusMessages(prev => [...prev, 'Initiating episode outline creation...']);
      setProgress(25);
      console.log('Initiating episode outline creation for show ID:', showData.new_show_id);
      try {
        const outlineResponse = await fetch(`${API_BASE_URL}/createshows/episode-outline`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id: user.user_id,
            show_id: showData.new_show_id
          }),
        });

        if (!outlineResponse.ok) {
          const errorData = await outlineResponse.json();
          throw new Error(errorData.message || 'Failed to create episode outlines');
        }

        const outlineData = await outlineResponse.json();
        setStatusMessages(prev => [...prev, 'Episode outlines created successfully.']);
        console.log('Episode outlines created successfully');

        // Step 4: Create Show Thumbnail
        setProgress(30);
        await createShowThumbnail(showData.new_show_id);

        // Step 5: Write Episodes
        await writeEpisodes(showData.new_show_id, outlineData.prompt, outlineData.answer);

        setStatusMessages(prev => [...prev, 'Show creation completed successfully.']);
        setProgress(100);
        navigate('/shows-list');

      } catch (outlineError) {
        console.error('Error creating episode outlines:', outlineError);
        setStatusMessages(prev => [...prev, `Error creating episode outlines: ${outlineError.message}`]);
        throw outlineError;
      }

    } catch (error) {
      console.error('Error in show creation process:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsCreating(false);
    }
  };

  const createShowThumbnail = async (new_show_id) => {
    try {
      setStatusMessages(prev => [...prev, 'Creating show thumbnail...']);
      const response = await fetch(`${API_BASE_URL}/createshows/create-show-thumbnail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          show_id: new_show_id,
          show_name: showName,
          show_description: showDescription,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.error) {
        throw new Error(data.error);
      } else if (data.status === 'Complete') {
        setStatusMessages(prev => [...prev, data.message]);
      } else {
        throw new Error('Unexpected response from server');
      }
  
    } catch (error) {
      console.error('Error creating show thumbnail:', error);
      throw new Error('Failed to create show thumbnail: ' + error.message);
    }
  };

  const writeEpisodes = async (new_show_id, prompt, answer) => {
    try {
      setStatusMessages(prev => [...prev, 'Writing episodes...']);
      const response = await fetch(`${API_BASE_URL}/createshows/write-episodes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id, 
          new_show_id, 
          prompt, 
          answer,
          voiceOption 
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decodedChunk = decoder.decode(value, { stream: true });
        const lines = decodedChunk.split('\n');
        lines.forEach(line => {
          if (line.trim() !== '') {
            try {
              const parsed = JSON.parse(line);
              if (parsed.error) {
                throw new Error(parsed.error);
              } else if (parsed.status) {
                setStatusMessages(prev => [...prev, parsed.status]);
                if (parsed.progress !== undefined) {
                  setProgress(parsed.progress);
                }
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
              throw e;
            }
          }
        });
      }

      setStatusMessages(prev => [...prev, 'Episodes written successfully.']);
    } catch (error) {
      console.error('Error writing episodes:', error);
      throw new Error('Failed to write episodes: ' + error.message);
    }
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      if (showName && showDescription && numberOfEpisodes && episodeDuration && 
          voiceOption && writingStyle && audienceAge && audienceExpertise && 
          visibility && research !== undefined) {
        createShow();
      } else {
        navigate('/create-show');
      }
    } else {
      navigate('/login');
    }
  }, [user.isAuthenticated, navigate, showName, showDescription, numberOfEpisodes, 
      episodeDuration, voiceOption, writingStyle, audienceAge, audienceExpertise, 
      visibility, research]);

  // Inline styles for the progress bar
  const progressBarStyle = {
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    margin: '10px 0'
  };

  const progressStyle = {
    width: `${progress}%`,
    backgroundColor: '#007BFF',
    height: '20px',
    borderRadius: '4px',
    transition: 'width 0.5s ease-in-out'
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.sectionHeader}>Creating Your Show</h1>
      {isCreating && (
        <>
          <p>Creating your new show. Please be patient...</p>
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
          <p>Current progress: {progress}%</p>
        </>
      )}
      {statusMessages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
      {error && (
        <div style={styles.errorMessage}>
          <p>Error: {error}</p>
          <button 
            onClick={() => {
              creationAttempted.current = false;
              navigate('/create-show');
            }} 
            style={styles.button}
          >
            Try Again
          </button>
        </div>
      )}
    </div>
  );
}

export default CompleteShowScreen;