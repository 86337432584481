// src/pages/EmailVerificationScreen.js

import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from '../styles';

function EmailVerificationScreen() {
  return (
    <div style={styles.registrationContainer}>
      <h1 style={styles.sectionHeader}>Email Verification</h1>
      <p>A verification link has been sent to your email address.</p> 
      <p>It may take a minute or two before you get it.</p>
      <p>If you don't see it, check your email spam folder.</p> 
      <p>After you verify your email, proceed to login.</p>
      <p style={{ marginTop: '20px' }}>
        <Link to="/login" style={styles.link}>Login here</Link>
      </p>
    </div>
  );
}

export default EmailVerificationScreen;