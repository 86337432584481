// src/pages/EpisodeDetailsScreen.js

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faFastForward, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../config';

function EpisodeDetailsScreen() {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { showId, episodeId } = location.state || {};

  const [showDetails, setShowDetails] = useState(null);
  const [episodeDetails, setEpisodeDetails] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaElement, setMediaElement] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showTranscript, setShowTranscript] = useState(false);
  const [episodeCount, setEpisodeCount] = useState(0);

  const progressBarRef = useRef(null);

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate('/login');
    }
  }, [user.isAuthenticated, navigate]);

  useEffect(() => {
    setIsPlaying(false);
    setCurrentTime(0);
    setDuration(0);
    if (mediaElement) {
      mediaElement.pause();
      mediaElement.currentTime = 0;
    }
  }, [episodeId]);

  useEffect(() => {
    const fetchShowDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-show-details`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.user_id, showId }),
        });
        const data = await response.json();
        if (response.ok) {
          setShowDetails(data);
          setEpisodeCount(Number(data.numberOfEpisodes));
        } else {
          throw new Error(data.error || 'Failed to fetch show details.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching show details: ${error.message}`);
      }
    };

    const fetchEpisodeDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-episode-detail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.user_id, showId, episodeId }),
        });
        const data = await response.json();
        if (response.ok) {
          setEpisodeDetails({
            ...data,
            episode_number: Number(data.episode_number)
          });
          if (data.has_video) {
            fetchEpisodeVideo();
          } else {
            fetchEpisodeAudio();
          }
        } else {
          throw new Error(data.error || 'Failed to fetch episode details.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching episode details: ${error.message}`);
      }
    };

    const fetchEpisodeAudio = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-episode-audio`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ episodeId, showId }),
        });
        const data = await response.json();
        if (response.ok) {
          setMediaUrl(data.audio_url || null);
          const audio = new Audio(data.audio_url || '');
          setMediaElement(audio);
          audio.addEventListener('loadedmetadata', () => {
            setDuration(audio.duration);
          });
          audio.addEventListener('timeupdate', () => {
            setCurrentTime(audio.currentTime);
          });
          setErrorMessage('');
        } else {
          throw new Error(data.error || 'Failed to fetch episode audio.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching episode audio: ${error.message}`);
        setMediaUrl(null);
      }
    };

    const fetchEpisodeVideo = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/viewshows/get-episode-video`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ episodeId, showId }),
        });
        const data = await response.json();
        if (response.ok) {
          setMediaUrl(data.video_url || null);
          setErrorMessage('');
        } else {
          throw new Error(data.error || 'Failed to fetch episode video.');
        }
      } catch (error) {
        setErrorMessage(`Error fetching episode video: ${error.message}`);
        setMediaUrl(null);
      }
    };

    if (user.user_id && showId && episodeId) {
      fetchShowDetails();
      fetchEpisodeDetails();
    }
  }, [showId, episodeId, user.user_id]);

  useEffect(() => {
    return () => {
      if (mediaElement) {
        mediaElement.pause();
        mediaElement.currentTime = 0;
      }
    };
  }, [mediaElement]);

  const handleTogglePlayPause = async () => {
    if (!mediaElement) return;

    try {
      if (isPlaying) {
        mediaElement.pause();
        setIsPlaying(false);
      } else {
        await mediaElement.play();
        setIsPlaying(true);
      }
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(`Error during media playback: ${error.message}`);
    }
  };

  const handleProgressBarClick = (e) => {
    if (!mediaElement || !progressBarRef.current) return;

    const progressBar = progressBarRef.current;
    const clickPosition = (e.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
    const newTime = clickPosition * duration;

    mediaElement.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDeleteShow = () => {
    navigate('/delete-show', { 
      state: { 
        showId: showId, 
        showName: showDetails.name, 
        numberOfEpisodes: showDetails.numberOfEpisodes 
      } 
    });
  };

  const handleNextEpisode = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/viewshows/get-next-episode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user.user_id, showId, episodeId }),
      });
      const data = await response.json();
      
      if (response.ok && data.nextEpisodeId) {
        navigate('/episode-details', { 
          state: { showId: showId, episodeId: data.nextEpisodeId },
          replace: true
        });
      } else if (response.status === 204) {
        setErrorMessage('This is the last episode of the show.');
      } else {
        throw new Error(data.error || 'Failed to fetch next episode.');
      }
    } catch (error) {
      setErrorMessage(`Error fetching next episode: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.linkContainer, textAlign: 'left', marginBottom: '20px' }}>
        <Link 
          to="/episodes-list" 
          state={{ showId: showId }} 
          style={styles.link}
        >
          &lt;&lt; Back to Episodes List
        </Link>
      </div>
      {errorMessage && <p style={styles.error}>{errorMessage}</p>}
      {episodeDetails && episodeDetails.has_video ? (
        <div style={styles.topVideoContainer}>
          <video
            src={mediaUrl}
            style={styles.topVideoPlayer}
            controls
          />
        </div>
      ) : (
        showDetails && (
          <div style={styles.showDetailsContainer}>
            <img 
              src={showDetails.thumbnail_url} 
              alt={showDetails.name} 
              style={styles.showThumbnail}
            />
          </div>
        )
      )}
      {episodeDetails && (
        <div>
          {episodeCount === 1 ? (
            <>
              <h1 style={styles.showTitle}>{showDetails?.name}</h1>
              <h2 style={styles.showDescription}>{showDetails?.description}</h2>
            </>
          ) : (
            <>
              <h1 style={styles.episodeTitle}>Episode {episodeDetails.episode_number}: {episodeDetails.title}</h1>
              <h2 style={styles.showNameDetails}>{showDetails?.name}</h2>
            </>
          )}
          {!episodeDetails.has_video && (
            <div style={{ ...styles.audioControlsContainer, flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '10px' }}>
                <div 
                  ref={progressBarRef}
                  style={styles.progressBar}
                  onClick={handleProgressBarClick}
                >
                  <div 
                    style={{
                      ...styles.progressBarFill,
                      width: `${(currentTime / duration) * 100}%`
                    }}
                  />
                </div>
                <span style={styles.timeDisplay}>
                  {formatTime(currentTime)} / {formatTime(duration)}
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  onClick={handleTogglePlayPause}
                  disabled={!mediaUrl}
                  style={styles.playPauseButton}
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </button>
                {episodeDetails.episode_number < episodeCount && (
                  <button
                    style={styles.nextEpisodeButton}
                    onClick={handleNextEpisode}
                  >
                    <FontAwesomeIcon icon={faFastForward} />
                  </button>
                )}
              </div>
            </div>
          )}
          {episodeCount === 1 
            ? <p></p>
            : <p style={styles.episodeDescription}>{episodeDetails.description}</p>
          }
          <div style={styles.transcriptContainer}>
            <h3 
              style={styles.transcriptHeader}
              onClick={() => setShowTranscript(!showTranscript)}
            >
              Transcript 
              <FontAwesomeIcon icon={showTranscript ? faChevronDown : faChevronUp} style={styles.transcriptIcon} />
            </h3>
            {showTranscript && (
              <p style={styles.transcriptText}>{episodeDetails.script}</p>
            )}
          </div>
          {episodeCount === 1 && (
            <div style={styles.deleteShowContainer}>
              <button 
                onClick={handleDeleteShow} 
                style={styles.deleteShowLink}
              >
                Delete Show
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EpisodeDetailsScreen;